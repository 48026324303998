<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-50" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                             leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
            </TransitionChild>

            <div class="fixed inset-0 z-40 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                                     enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                     enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                                     leave-from="opacity-100 translate-y-0 sm:scale-100"
                                     leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start md:items-center justify-center">
                                    <div class="mt-3 text-center sm:ml-4 md:ml-0  sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3"
                                                     class="text-base font-semibold leading-6 text-gray-900 mb-3 text-center">
                                            {{ title }}
                                        </DialogTitle>
                                        <div>
                                            <div class="sm:flex sm:flex-row-reverse justify-center">
                                                <button type="button" @click="onConfirmClick"
                                                        class="inline-flex w-full justify-center rounded-md bg-brand-color px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                                                        :disabled="loadingState">
                                                    <div v-if="loadingState" role="status">
                                                        <svg aria-hidden="true"
                                                             class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white"
                                                             viewBox="0 0 100 101" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                  fill="currentColor"/>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                  fill="currentFill"/>
                                                        </svg>
                                                    </div>
                                                    {{ confirmButtonText }}
                                                </button>
                                                <button type="button"
                                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                        @click="cancel" ref="cancelButtonRef">Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {ref, onMounted} from 'vue'
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";
import {userStore} from "~/store/userStore";
import {clubStore} from "~/store/clubStore";
import {eventStore} from "~/store/eventStore";
import type {LocalParticipant} from "~/models/localParticipant";
import {ticketStore} from "~/store/ticketStore";


const useUserStore = userStore()
const useClubStore = clubStore()
const useEventStore = eventStore()
const useTicketStore = ticketStore()
const {inviteGuestToTheEvent} = useEventStore
const {getUserDetailsByEmail} = useUserStore
const {getEventTicketsByUserIdStudentIdAndEventId} = useTicketStore
const useCompanyStore = companyStore()
const {getCompanyId} = storeToRefs(useCompanyStore)

const {
    fire,
    onCloseDialogue,
    title,
    confirmButtonText,
    onConfirm
} = defineProps(['fire', 'onCloseDialogue', 'title', 'confirmButtonText', 'onConfirm'])

const clubId = ref<number>();
const localStudent = ref<LocalParticipant>(<LocalParticipant>{});
const loadingState = ref<boolean>(false)
const open = ref(fire)

function cancel() {
    open.value = false;
    loadingState.value = false
    onCloseDialogue();
}

const onConfirmClick = async () => {
    loadingState.value = true;
    await onConfirm();
    onCloseDialogue();
    loadingState.value = false;
}


onMounted(() => {
})

</script>

<style scoped>

</style>